<template>
  <v-card class="mb-7" elevation="0" outlined tile>
    <v-card-text class="pa-5">
      <div>
        <vue-apex-charts type="bar" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'G4PresentadoVsAprobadoXArea',
  props: {
    datos: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    async datos(val) {
      let presentado = [];
      let aprobado = [];
      let categorias = [];
      let formatCategorias = [];
      let formatPresentado = [];
      let formatAprobado = [];
      val.forEach((el) => {
        categorias.push(el.idsubarea);
        formatCategorias.push(el.subarea);
        presentado.push(el.totalpresentado);
        aprobado.push(el.totalaprobado);
        formatPresentado.push(el.formatopresentado);
        formatAprobado.push(el.formatoaprobado);
      });
      this.series[0] = { ...this.series[0], data: presentado, format: formatPresentado };
      this.series[1] = { ...this.series[1], data: aprobado, format: formatAprobado };
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            type: 'category',
            categories: categorias,
            formatCategories: formatCategorias,
            ...this.chartOptions.xaxis.labels
          }
        }
      };
    }
  },
  data: () => ({
    series: [
      { name: 'PRESENTADOS', data: [], format: [] },
      { name: 'APROBADOS', data: [], format: [] }
    ],
    chartOptions: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true, // barras encima
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      title: {
        text: 'PRESUPUESTO PRESENTADO/APROBADO POR ÁREA',
        align: 'center',
        margin: 5,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgba(0, 0, 0, 0.8)'
        }
      },
      grid: {
        show: true,
        borderColor: 'rgba(0, 0, 0, .3)',
        strokeDashArray: 2,
        padding: {
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 0,
          barHeight: '101%'
          /*dataLabels: {
            position: 'top',
            maxItems: 100,
            hideOverflowingLabels: true,
            orientation: 'vertical'
          }*/
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val, obj) {
          return val > 0 ? obj.w.config.series[obj.seriesIndex].format[obj.dataPointIndex] : '';
        },
        style: {
          fontSize: '11px'
        }
      },
      /*responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],*/
      xaxis: {
        type: 'category',
        categories: [],
        formatCategories: [],
        labels: {
          trim: true,
          style: {
            fontSize: '10px',
            fontWeight: 'bolder',
            cssClass: 'text-muted fill-color'
          }
        }
      },
      yaxis: {
        labels: {
          trim: true,
          style: {
            fontSize: '12px',
            fontWeight: 'bolder',
            cssClass: 'text-muted fill-color'
          }
        }
      },
      tooltip: {
        x: {
          show: true,
          formatter: (val, opt) => {
            return opt.w.config.xaxis.formatCategories[opt.dataPointIndex];
          }
        }
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '11px',
        fontFamily: 'Roboto, Helvetica, Arial',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          colors: '#424242',
          useSeriesColors: false
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      fill: {
        type: 'solid',
        opacity: 1
      },
      colors: ['#1d4b23', '#007e49']
      /*title: {
        text: 'PRESUPUESTO PRESENTADO/APROBADO POR ÁREA',
        align: 'center',
        margin: 5,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgba(0, 0, 0, 0.8)'
        }
      },
      labels: [],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 0
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val, obj) {
          return obj.w.config.series[obj.seriesIndex].format[obj.dataPointIndex];
        },
        style: {
          fontSize: '9px'
        }
      },
      xaxis: {
        categories: [],
        formatCategories: [],
        labels: {
          trim: false,
          style: {
            fontSize: '10px',
            fontWeight: 'bolder',
            cssClass: 'text-muted fill-color'
          }
          //formatter: function(val, obj) {
            7/return obj?.w.config.xaxis?.formatCategories[obj.dataPointIndex] ?? '';
          //}
        }
      },
      yaxis: {
        labels: {
          trim: false,
          style: {
            fontSize: '10px',
            fontWeight: 'bolder',
            cssClass: 'text-muted fill-color'
          }
        }
      },
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '11px',
        fontFamily: 'Roboto, Helvetica, Arial',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      fill: {
        opacity: 1
      }
      /*,
      colors: ['#00E676', '#448AFF']*/
    }
  }),
  components: {
    VueApexCharts
  }
};
</script>
